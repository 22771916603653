<template lang="pug">
  v-row(justify="center")
    v-dialog(
      v-model="dialog"
      eager
      max-width="1300px"
    )
      v-card
        v-toolbar(dark color="primary")
          v-toolbar-title(v-if="user") Client: \#{{user.id}}
        
        v-card-text
          v-container
            form-client(v-model="user" ref="formClient")
        
        v-card-actions
          v-spacer
          
          v-btn(
            color="blue darken-1"
            text
            @click="dialog = false"
          ) Fechar

          v-btn(
            color="blue darken-1"
            text
            @click="dialog = false"
          ) Salvar
        
</template>

<script>
  export default {

    components: {
      'form-client': () => import( '@/views/Clients/components/FormClient' ),
    },
    data: () => ({
      dialog: false,
      user: null
    }),

    methods: {
      open: function( client ){
        this.user = client;
        this.dialog = true;
        this.$refs.formClient.getById( client.id )       
      },
    },
  }
</script>